import {
  ErrorId,
  UploadingItem,
  Status,
  ConflictActions,
} from '../reducers/uploadReducer';
import { toast, toastType } from '../services/toastService';
import { getFileExtension } from '../services/utillities';
import { invalidateData } from './dataActions';
import i18n from '../i18n';
import { getFormattedFileSize } from '../services/dataFormatters';
import {
  ENQUEUE_FILES,
  UPLOAD_FINISH,
  FILE_UPLOAD_FINISH,
  FILE_UPLOAD_START,
  FILE_UPLOAD_PROGRESS,
  FILE_UPLOAD_FAIL,
} from './actionConstants';
import {
  invalidFileTypes,
  getFileUploadUrl,
  validateFile,
  getUploadStartBody,
  uploadFileToBlob,
  getUploadFinishBody,
  getReplaceFinishBody,
} from '../services/uploadHelper';
import { MAX_UPLOAD_FILE_SIZE } from '../constants';
import { AppState } from '../store/configureStore';
import { Dispatch, AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { defaultShareOptions } from '../services/fetchHelper';
import { logger } from '../services/logger';
import { ConflictingItem } from '../components/modals/resolveConflictsModal';

export const enqueueUploadFiles = (
  items: ConflictingItem[],
  parentInstanceId: string
) => {
  return {
    type: ENQUEUE_FILES,
    payload: { files: items, parentInstanceId },
  };
};

export const finishUpload = () => {
  return {
    type: UPLOAD_FINISH,
  };
};

export const startFileUpload = (id: string) => {
  return {
    type: FILE_UPLOAD_START,
    payload: id,
  };
};

export const updateFileUploadProgress = (id: string, progress: number) => {
  return {
    type: FILE_UPLOAD_PROGRESS,
    payload: { id: id, progress },
  };
};

export const finishFileUpload = (id: string) => {
  return {
    type: FILE_UPLOAD_FINISH,
    payload: id,
  };
};

export const fileUploadFail = (id: string, errorId: ErrorId) => {
  return {
    type: FILE_UPLOAD_FAIL,
    payload: { id: id, errorId },
  };
};

export const onStartFileUpload = (
  id: string
): ThunkAction<Promise<void>, AppState, {}, AnyAction> => {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    const item = getState().upload.uploadingItems.find(x => x.id === id)!;
    dispatch(startFileUpload(item.id));

    const file = item.file;
    const [isFileValid, errorId] = validateFile(file);
    if (!isFileValid) {
      logger.logWarn(`Did not allow to start upload: ${errorId}`, [errorId]);
      dispatch(fileUploadFail(item.id, errorId));
      return;
    }

    let accessUrl: string = '';
    let instanceId: string = '';
    let startUploadUrl: string = '';
    const startUploadBody = JSON.stringify(
      getUploadStartBody(file, item.parentInstanceId)
    );
    startUploadUrl = getFileUploadUrl(
      getState().urls.projectShareUrl,
      getState().navigation.projectId
    );
    if (item.actionType === ConflictActions.Upload) {
      logger.logInfo(
        `Started create file upload start: ${startUploadUrl}, ${startUploadBody}`,
        [startUploadUrl, startUploadBody]
      );
      const response = await fetch(startUploadUrl, {
        ...defaultShareOptions(),
        method: 'POST',
        body: startUploadBody,
      });

      const json = await response.json();
      if (json.errorMessage) {
        logger.logError(
          `Failed to start upload: ${ErrorId.UploadOperationFailed}`,
          [ErrorId.UploadOperationFailed]
        );
        dispatch(fileUploadFail(item.id, ErrorId.UploadOperationFailed));
        return;
      }

      instanceId = json.changedInstance.instanceAfterChange.instanceId;
      accessUrl = json.changedInstance.instanceAfterChange.properties.AccessUrl;
    } else if (
      item.actionType === ConflictActions.Replace &&
      item.relatedInstance
    ) {
      instanceId = item.relatedInstance.instanceId as string;
      accessUrl = item.relatedInstance.accessUrl as string;
    }

    const uploadBlobResponse = await uploadFileToBlob(accessUrl, file, event =>
      dispatch(updateFileUploadProgress(item.id, event.loadedBytes / file.size))
    );

    if (uploadBlobResponse.errorCode) {
      logger.logError(
        `Failed uploading file: ${ErrorId.UploadOperationFailed}`,
        [ErrorId.UploadOperationFailed]
      );
      dispatch(fileUploadFail(item.id, ErrorId.UploadOperationFailed));
      return;
    }

    let finishMessage = '';
    let finishBody;
    if (item.actionType === ConflictActions.Upload) {
      finishMessage = 'Finished Uploading Sucessfully';
      finishBody = getUploadFinishBody(instanceId);
    } else if (item.actionType === ConflictActions.Replace) {
      finishMessage = 'Finished Replacing Sucessfully';
      finishBody = getReplaceFinishBody(instanceId, item.file.size);
    }

    const finishUploadUrl = `${startUploadUrl}/${instanceId}`;
    const finishUploadBody = JSON.stringify(finishBody);
    logger.logInfo(
      `Started create file upload finish: ${finishUploadUrl}, ${finishUploadBody}`,
      [finishUploadUrl, finishUploadBody]
    );
    await fetch(finishUploadUrl, {
      ...defaultShareOptions(),
      method: 'POST',
      body: finishUploadBody,
    });

    logger.logInfo(finishMessage);
    dispatch(finishFileUpload(item.id));
  };
};

export const onUploadFinish = (): ThunkAction<
  Promise<void>,
  AppState,
  {},
  AnyAction
> => {
  return async (dispatch: any, getState: any) => {
    const errorIds: ErrorId[] = getState().upload.errorIds;
    const uploads: UploadingItem[] = getState().upload.uploadingItems;
    const failedItems = uploads.filter(item => item.status === Status.Error);

    const successfulCount = uploads.filter(
      item => item.status === Status.Success
    ).length;
    if (successfulCount) {
      successfulCount == 1
        ? toast(toastType.success, 'Upload File')
        : toast(toastType.success, 'Upload Files');
    }

    showToastsForErrors(errorIds, failedItems);

    dispatch(finishUpload());
    dispatch(invalidateData());
    if (document.getElementsByClassName('rt-tbody')[0]) {
      document.getElementsByClassName('rt-tbody')[0].scrollTop = 0;
    }
  };
};

function showToastsForErrors(
  errorIds: ErrorId[],
  failedItems: UploadingItem[]
) {
  let foundInvalidTypes: string[] = [];

  function getFailedFilesDescription(failedItems: UploadingItem[]) {
    const failedItemNames = failedItems.map(item => item.file.name);
    const failedItemCount = failedItems.length;

    if (failedItemCount > 2) {
      return (
        failedItemNames.slice(0, 2).join(', ') +
        ' and ' +
        (failedItemCount - 2) +
        ' more files'
      );
    }
    return failedItemNames.join(', ');
  }

  failedItems.forEach((item: UploadingItem) => {
    const fileType = getFileExtension(item.file.name).toLowerCase();
    if (
      invalidFileTypes.includes(fileType) &&
      !foundInvalidTypes.includes(fileType)
    ) {
      foundInvalidTypes.push(fileType);
    }
  });

  if (errorIds.includes(ErrorId.InvalidType)) {
    toast(
      toastType.error,
      i18n.t('Files Not Supported', {
        restrictedTypes: foundInvalidTypes.map(type => '*.' + type).join(', '),
      }),
      {},
      false
    );
  }
  if (errorIds.includes(ErrorId.InvalidName)) {
    toast(
      toastType.error,
      i18n.t('File Contains Illegal Characters', {
        illegalCharacters: '/ : * ? " <> |',
      }),
      {},
      false
    );
  }
  if (errorIds.includes(ErrorId.NameTooLong)) {
    toast(toastType.error, i18n.t('File Name Is Too Long'), {}, false);
  }
  if (errorIds.includes(ErrorId.SizeTooBig)) {
    toast(
      toastType.error,
      i18n.t('File Size Is Too Big', {
        maxSize: getFormattedFileSize(MAX_UPLOAD_FILE_SIZE.toString()),
      }),
      {},
      false
    );
  }
  if (errorIds.includes(ErrorId.UploadOperationFailed)) {
    toast(
      toastType.error,
      i18n.t('Upload Operation Failed', {
        failedFilesDescription: getFailedFilesDescription(failedItems),
      }),
      {},
      false
    );
  }
}
