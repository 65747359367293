import { getProjectShareUrl } from './utillities';
import { defaultShareOptions } from './fetchHelper';
import { toastType, toast } from './toastService';
import { logger } from './logger';

export const postToImodelHubRequest = (inputFiles: string[]) => {
  const body = JSON.stringify(data(inputFiles));
  const url = `${getProjectShareUrl()}/PostToImodelhub`;
  logger.logInfo(`Started post to imodel: ${url}, ${body}`, [url, body]);
  return fetch(url, { ...defaultShareOptions(), method: 'POST', body })
    .then(response => response.status)
    .then(response => {
      if (response === 201) {
        logger.logInfo('Posted to imodelhub successfully');
        toast(
          toastType.success,
          inputFiles.length == 1
            ? 'Post Imodel Success'
            : 'Post Imodels Success'
        );
      } else {
        logger.logError(`Post Imodel Failed: ${response}`, [response]);
        toast(
          toastType.error,
          inputFiles.length == 1 ? 'Post Imodel Failed' : 'Post Imodels Failed'
        );
      }
    });
};

const data = (inputFiles: string[]) => {
  return {
    instance: {
      schemaName: 'ProjectShare',
      className: 'PostToImodelhub',
      changeState: 'new',
      properties: {
        InputFiles: inputFiles,
      },
    },
  };
};
