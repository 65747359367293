import { defaultShareOptions } from '../services/fetchHelper';
import { getProjectShareUrl } from '../services/utillities';
import { toast, toastType } from '../services/toastService';
import { Item } from '../entities/entities';
import { mapToItem } from './dataActions';
import { logger } from '../services/logger';

export const createVersion = (item: Item, version: string, comment: string) => {
  return () => {
    const body = JSON.stringify(data(item, version, comment));
    const url = `${getProjectShareUrl()}/FileVersion`;
    logger.logInfo(`Started creating version: ${url}, ${body}`, [url, body]);
    return fetch(url, { ...defaultShareOptions(), method: 'POST', body })
      .then(response => response.json())
      .then(response => {
        if (response.errorMessage) {
          logger.logError(
            `Failed to create version: ${response.errorMessage}`,
            [response.errorMessage]
          );
          toast(toastType.error, 'Failed to create a version');
        } else {
          logger.logInfo('Created version successfully');
          toast(toastType.success, 'Version has been created');
        }
      });
  };
};

const data = (item: Item, version: string, comment: string) => {
  return {
    instance: {
      schemaName: 'ProjectShare',
      className: 'FileVersion',
      properties: { Version: version, Comment: comment },
      relationshipInstances: [
        {
          schemaName: 'ProjectShare',
          className: 'FileHasVersion',
          direction: 'backward',
          relatedInstance: {
            schemaName: 'ProjectShare',
            className: 'File',
            instanceId: item.instanceId,
            changeState: 'existing',
          },
        },
      ],
    },
  };
};

export const fetchVersions = (item: Item): any => {
  return (): Promise<Item[]> => {
    const url = `${getProjectShareUrl()}/FileVersion?$filter=FileHasVersion-backward-File.$id+eq+'${
      item.instanceId
    }'&skip=0&top=10&$orderby=ModifiedTimeStamp desc`;
    logger.logInfo(`Started fetching versions: ${url}`, [url]);
    return fetch(url, { ...defaultShareOptions(), method: 'GET' })
      .then(response => response.json())
      .then(response => {
        logger.logInfo('Finished fetching versions');
        return response.instances.length == 0
          ? []
          : mapToItem(response.instances);
      });
  };
};

export const promoteVersion = (item: Item, versionId: string) => {
  return () => {
    const body = JSON.stringify({
      instance: {
        instanceId: item.instanceId,
        schemaName: 'ProjectShare',
        className: 'File',
        changeState: 'modified',
      },
      requestOptions: { CustomOptions: { UpdateFromVersion: versionId } },
    });
    const url = `${getProjectShareUrl()}/File/${item.instanceId}`;
    return fetch(url, { ...defaultShareOptions(), method: 'POST', body })
      .then(response => {
        response.ok
          ? logger.logInfo('Promoted version successfully')
          : logger.logError(
              `Failed to promote version: ${response.statusText}`,
              [response.statusText]
            );
      })
      .catch(error => {
        logger.logError(`Failed to promote version: ${error}`, [error]);
      });
  };
};
