import React, { useEffect, useState } from 'react';

function useCONNECTHeader(
  userEmail: string,
  connectPortalUrl: string,
  projectId: string
) {
  const [headerFooterLoaded, setHeaderFooterLoaded] = useState(false);

  useEffect(
    function addHeaderFooterScript() {
      if (connectPortalUrl) {
        let headerFooterScript = document.createElement('script');
        headerFooterScript.onload = () => {
          setHeaderFooterLoaded(true);
        };
        headerFooterScript.src = `${connectPortalUrl}/Scripts/header-footer.js`;
        (document.head as any).appendChild(headerFooterScript);
      }
    },
    [connectPortalUrl]
  );

  useEffect(
    function initializeHeaderFooter() {
      headerFooterLoaded &&
        //@ts-ignore
        window.GlobalHeaderFooter.setup({
          // See chart below for all the keys/values that go inside of here.
          Email: userEmail,
          CurrentProjectId: projectId,
          CurrentServiceId: '7AF6119E-900B-415C-9A03-B617C9D7D239',
          HelpUrl:
            'https://communities.bentley.com/communities/other_communities/sign-in_assistance_and_web_services/w/wiki/33706/projectwise-share',
          LegalUrl: `${connectPortalUrl}/Legal`,
          SignOutUrl: `/logout/${projectId}`,
        });
    },
    [headerFooterLoaded, userEmail]
  );
}

interface ConnectHeaderProps {
  userEmail: string;
  connectUrl: string;
  projectId: string;
}

const CONNECTHeader = ({
  userEmail,
  connectUrl,
  projectId,
}: ConnectHeaderProps) => {
  useCONNECTHeader(userEmail, connectUrl, projectId);
  return <nav id="GlobalHeader" style={{ margin: 0 }} />;
};

export default CONNECTHeader;
