import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Column } from 'react-table';
import cx from 'classnames';
//hooks
import { useTranslation } from 'react-i18next';
import { useToggle } from './hooks/useToggle';
import { IBreadcrumb } from './hooks/useBreadcrumb';
import {
  useIsWidthSmaller,
  useIsWidthSmallerBreakpoints,
} from './hooks/useIsWidthSmaller';
//bwc-react
import { SelectTable } from '@bentley/bwc-react/innersource/Grids/ReactTable/SelectTable';
import { Header } from '@bentley/bwc-react/innersource/Grids/ReactTable/Header/Header';
// Moving TableBreadCrumbs from bwc to test workfolw with IE and fix bugs to be assured that functionality will work with dropdown
import { TableBreadCrumbs } from '@bentley/bwc-react/core/TableComponents/TableBreadCrumbs/TableBreadCrumbs';
import { Spinner } from '@bentley/bwc-react/core/ProgressIndicators/Spinner';
import { ButtonWithDropdown } from '@bentley/bwc-react/innersource/Buttons/ButtonWithDropdown';
import {
  Toolbar,
  MenuItemAction,
} from '@bentley/bwc-react/innersource/Toolbar/Toolbar';

//bwc-react/icons
import { SvgFolder } from '@bentley/bwc-react/icons/Folder';
import { SvgUpload } from '@bentley/bwc-react/icons/Upload';
import { SvgVersions } from '@bentley/bwc-react/icons/Versions';
import { SvgDownload } from '@bentley/bwc-react/icons/Download';
import { SvgSettings } from '@bentley/bwc-react/icons/Settings';
import { SvgAdd } from '@bentley/bwc-react/icons/Add';
import { SvgLink } from '@bentley/bwc-react/icons/Link';
import { SvgRename } from '@bentley/bwc-react/icons/Rename';
import { SvgRedo } from '@bentley/bwc-react/icons/Redo';
import { SvgDelete } from '@bentley/bwc-react/icons/Delete';
import { SvgInfo2 } from '@bentley/bwc-react/icons/Info2';
import { SvgInfoHollow } from '@bentley/bwc-react/icons/InfoHollow';
import { SvgSync } from '@bentley/bwc-react/icons/Sync';
import { SvgLockUnlocked } from '@bentley/bwc-react/icons/LockUnlocked';
import { SvgLock } from '@bentley/bwc-react/icons/Lock';
import { SvgArrowRight } from '@bentley/bwc-react/icons/ArrowRight';
import { SvgImodelHollow } from '@bentley/bwc-react/icons/ImodelHollow';
import { SvgTechnicalPreviewMini } from '@bentley/bwc-react/icons/TechnicalPreviewMini';
import { IdeasButton } from '@bentley/bwc-react/innersource/Buttons/IdeasButton';
//components
import InformationPanel from './components/informationPanel/InformationPanel';
import { DragAndDrop } from './components/dragAndDrop';
import { copyToClipBoard } from './services/getLinkService';
import CreateFolderModal from './components/modals/createFolderModal';
import UploadProgressIndicator from './components/UploadProgressIndicator';
import RenameItemModal from './components/modals/renameItemModal';
import PermanentlyDeleteModal from './components/modals/permanentlyDeleteModal';
import { ConfirmationForXfdfFile } from './components/modals/confirmationDialogForXfdf';
import CreateVersionModal from './components/modals/createVersionModal';
import ManageVersionsModal from './components/modals/manageVersionsModal';
import ResolveConflictsModal from './components/modals/resolveConflictsModal';
import Search from './components/search';
import SetFolderAccessModal from './components/modals/setFolderAccessModal';
import { getIcon } from './components/table/itemIcon';
import { SvgRecycle } from './components/icons/Recycle';
import FolderSelectDialog from './components/folderSelectDialog/folderSelectDialog';
import { GenericErrorPage } from './components/errorPages/genericErrorPage';
import UploadQueueObserver from './components/uploadQueueObserver';

//styles
import './components/table/tableStyle.css';
//entities
import { Item } from './entities/entities';
//services
import {
  getFormattedDateAndTime,
  getFormattedFileSize,
} from './services/dataFormatters';
import {
  allowDownload,
  allowGetLink,
  allowRename,
  allowRecycle,
  allowSetFolderAccess,
  allowSync,
  areAllFoldersSynced,
  allowLock,
  allowUnlock,
  allowFilesLockUnlockInFolder,
  areAllParentsUnSynced,
  allowCreateVersion,
  allowMove,
  containsFolder,
  allowPostToImodelHub,
  areAllItemsIdgn,
  containsFile,
  hasItemReadPermissions,
  hasItemWritePermissions,
  containsLockedByOther,
} from './services/permissionsService';
import {
  setBreadcrumbsFromItemPath,
  openFileInNewWindow,
  getOfficeToolTip,
  itemNameSortFunction,
  itemSizeSortFunction,
  getFileExtension,
  getFileType,
} from './services/utillities';
import { createFolderRequest } from './services/createFolderService';
//actions
import {
  setProjectId,
  setCurrentFolderId,
  setCurrentFolderAccessOrigin,
} from './actions/initializationActions';
import {
  jumpTo,
  addBreadcrumb,
  jumpToById,
  initializeBreadcrumbs,
} from './actions/breadcrumbsActions';
import {
  fetchData,
  fetchFolder,
  fetchFolderData,
  fetchRecycleBinData,
  fetchSearchResults,
  fetchDataByPath,
  mapToItem,
  setIsSearch,
  invalidatePagination,
  setPage,
  setPages,
  fetchNextPage,
} from './actions/dataActions';
import { deleteItems, restoreItems } from './actions/deleteItemsActions';
import { tryDownloadFiles } from './actions/downloadActions';
import {
  synchronizeFolders,
  unSynchronizeFolders,
  isSyncedFolder,
} from './actions/synchronizeFoldersActions';
import { setSelection, invalidateSelection } from './actions/selectionActions';
import { lockItem } from './actions/lockItemAction';
import { move } from './actions/moveActions';
import { authenticateUser } from './actions/postToImodelHubAction';
//reducers
import { BreadcrumbsState } from './reducers/breadcrumbsReducer';
import { FeaturesState } from './reducers/featuresReducer';
import { AppState, store } from './store/configureStore';
import ContextMenu from './components/contextMenu/contextMenu';
import './components/styles.css';
import { setSearchText } from './actions/searchActions';
import { toast, toastType } from './services/toastService';
import { ShareLoader } from './components/icons/ShareLoader';
import {
  getRowClickSelection,
  handleCheckBoxSelection,
} from './components/table/tableShiftSelect';
import { featureTracker } from './services/featureTrackingService';
import { enqueueTransferItems } from './services/uploadHelper';
import { EmptyRecycleBinGrid } from './components/emptyGrids/emptyRecycleBinGrid';
import { EmptyDocumentsGrid } from './components/emptyGrids/emptyDocumentsGrid';

function callResizeEvent() {
  let event;
  if (typeof Event === 'function') {
    // in case if scroll appeared
    // normal browsers
    event = new Event('resize');
  } else {
    // IE only
    event = window.document.createEvent('UIEvents');
    (event as any).initUIEvent('resize', true, false, window, 0);
  }
  window.dispatchEvent(event);
}

const getCell = (
  item: any,
  icon: boolean = false,
  onClick?: (...args: any[]) => any
) => (
  <div className={'cell-style'} role="gridcell">
    {getIcon(item, icon)}
    <span
      className={cx('folder-name', onClick ? ' itemLink' : '')}
      onKeyPress={onClick}
      title={getOfficeToolTip(item)}
      role="link"
      tabIndex={0}
      onClick={e => {
        e.stopPropagation();
        if (onClick) {
          onClick(e);
        }
      }}
    >
      {item.name}
      {(getFileType(item.name) === 'Excel' ||
        getFileType(item.name) === 'Word' ||
        getFileType(item.name) === 'PowerPoint') &&
      store.getState().features.isWopiDeprecationInformationEnabled ? (
        <SvgInfoHollow
          className="bwc-icons-small"
          style={{ maxWidth: '12%', marginLeft: '4px' }}
        />
      ) : (
        <></>
      )}
    </span>
    <div className="synced-folder-icon">
      {isSyncedFolder(item.instanceId) && areAllParentsUnSynced() ? (
        <SvgSync />
      ) : null}
    </div>
  </div>
);

type StateProps = {
  projectId: string;
  currentFolderId: string;
  currentFolderAccessOrigin: any;
  context: string;
  items: Item[];
  isLoading: boolean;
  isUploading: boolean;
  isInitialLoad: boolean;
  isSearch: boolean;
  emptyTableLabel: string;
  searchText: string;
  error: { errorTitle: string; errorDetails: string };
  urls: any;
  breadcrumbs: BreadcrumbsState;
  features: FeaturesState;
  selectedItems: Item[];
  selectedItemsIds: string[];
  isLastPage: boolean;
  rootFolderName: string;
  linkFormat: string;
  hasSetFolderPermissions: boolean;
};

type ActionProps = {
  setProjectId: (projectId: string) => void;
  setCurrentFolderId: (folderId: string) => void;
  setCurrentFolderAccessOrigin: (accessOrigin: any) => void;
  fetchData: (
    projectId: string,
    folderId?: string,
    searchText?: string,
    path?: string,
    isRecycle?: boolean
  ) => any;
  fetchFolderData: (
    folderId: string,
    onlyFolders?: boolean,
    doReceive?: boolean,
    page?: number
  ) => Promise<Item[]>;
  fetchRecycleBinData: () => void;
  fetchDataByPath: (path: string) => Promise<Item[]>;
  fetchSearchResults: (searchText: string) => any;
  fetchNextPage: () => any;
  setSelection: (items: string[]) => void;
  invalidateSelection: () => void;
  deleteItems: (items: Item[], isRecycle: boolean) => any;
  fetchFolder: (itemID: string) => any;
  restoreItems: (items: Item[], isRecycleBin: boolean) => any;
  synchronizeFolders: (items: Item[]) => any;
  unSynchronizeFolders: (items: Item[]) => any;
  jumpTo: (value: string) => void;
  jumpToById: (index: number, value: string) => void;
  addBreadcrumb: (value: string, label: string) => void;
  initializeBreadcrumbs: (values: string[], label: string[]) => void;
  setSearchText: (text: string) => void;
  setIsSearch: (isSearch: boolean) => void;
  lockItem: (items: Item[], lockStatus: boolean) => void;
  authenticateUser: (
    selectedItems: Item[],
    currentFolderId: string,
    projectId: string,
    domainUrl: string,
    linkFormat: string
  ) => void;
  invalidatePagination: () => void;
  setPage: (page: number) => void;
  setPages: (pages: number) => void;
  move: (itemsToMove: Item[], newParentId: string, oldParentId: string) => any;
};

const ProjectShare: React.FunctionComponent<StateProps & ActionProps> = (
  props: StateProps & ActionProps
) => {
  const { t } = useTranslation();
  const {
    projectId,
    context,
    items,
    isLoading,
    error,
    isInitialLoad,
    currentFolderId,
    currentFolderAccessOrigin,
    selectedItems,
    selectedItemsIds,
    isLastPage,
  } = props;
  const uploadRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isInitialLoad && !props.isSearch) {
      props.fetchFolderData(currentFolderId);
    }
  }, [isInitialLoad]);

  useEffect(() => {
    if (items.length > 0) {
      props.setCurrentFolderAccessOrigin(items[0].parentFolderId);
    }
    if (props.currentFolderId != '') {
      props.fetchFolder(currentFolderId).then((item: Item) => {
        props.setCurrentFolderAccessOrigin(item.accessOrigin);
      });
    }
  }, [currentFolderId]);

  if (isInitialLoad && props.isSearch && props.searchText !== '') {
    props.fetchSearchResults(props.searchText);
  }

  useEffect(() => {
    if (props.isSearch && props.selectedItems) {
      if (props.selectedItems.length === 1) {
        setBreadcrumbsFromItemPath([props.selectedItems[0]]);
      } else {
        props.fetchFolder(props.currentFolderId).then((item: Item) => {
          setBreadcrumbsFromItemPath(
            props.currentFolderId === props.projectId ? undefined : [item]
          );
        });
      }
    }
  }, [props.isSearch, props.selectedItems]);

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [gridSorted, setGridSorted] = useState<any[]>([]);
  const [targetFolderToMoveItem, setTargetFolderToMoveItem] = useState<string>(
    ''
  );

  const [
    isConfirmationModalForMoveOpen,
    toggleConfirmationModalForMove,
  ] = useToggle(false);
  const [
    isConfirmationModalForDeleteOpen,
    toggleConfirmationModalForDelete,
  ] = useToggle(false);

  const [lastSelectedWithoutShift, setLastSelectedWithoutShift] = useState<
    string
  >('');
  const [uploadingItems, setUploadingItems] = useState<File[]>([]);
  const [uploadingFolders, setUploadingFolders] = useState<Item[]>([]);
  const [conflictingItems, setConflictingItems] = useState<File[]>([]);
  const [conflictingExistingFiles, setConflictingExistingFiles] = useState<
    Item[]
  >([]);
  const [isCreateFolderModalOpen, toggleCreateFolderModal] = useToggle(false);
  const [isRenameItemModalOpen, toggleIsRenameItemModal] = useToggle(false);
  const [isResolveConflicsModalOpen, toggleResolveConflictsModal] = useToggle(
    false
  );
  const [
    isPermanentlyDeleteModalOpen,
    togglePermanentlyDeleteModal,
  ] = useToggle(false);
  const [isCreateVersionModalOpen, toggleCreateVersionModal] = useToggle(false);
  const [isManageVersionModalOpen, toggleManageVersionsModal] = useToggle(
    false
  );
  const [isProgressIndicatorOpen, toggleProgressIndicator] = useToggle(false);
  const [isFolderAccessModalOpen, toggleFolderAccessModal] = useToggle(false);
  const [isMoveModalOpen, toggleMoveModal] = useToggle(false);
  const [isRecycleBin, toggleRecycleBin] = useToggle(false);
  const [
    isInformationPanelOpen,
    toggleInformationPanel,
    closeInformationPanel,
  ] = useToggle(false);
  const [contextMenuOpen, toggleContextMenu, closeContext] = useToggle(false);

  const tableAreaRef = useRef<HTMLDivElement>(null);
  const leftToolbarRef = useRef<HTMLDivElement>(null);
  const rightToolbarRef = useRef<HTMLDivElement>(null);

  // Mobile
  const [isSearchCollapsed, setIsSearchCollapsed] = useState<boolean>(true);
  const isScreenMobile = useIsWidthSmaller(tableAreaRef, 768);
  const showFileSizeCol = !useIsWidthSmaller(tableAreaRef, 700);
  const showTypeCol = !useIsWidthSmaller(tableAreaRef, 600);
  const showModifiedDateCol = !useIsWidthSmaller(tableAreaRef, 500);
  const leftToolbarItemsShownCount = useIsWidthSmallerBreakpoints(
    leftToolbarRef,
    [0, 1, 2],
    [100, 150, 200],
    3
  );
  const rightToolbarItemsShownCount = useIsWidthSmallerBreakpoints(
    rightToolbarRef,
    [0, 1],
    [100, 150],
    3
  );
  useEffect(() => {
    if (tableAreaRef != null && tableAreaRef.current != null) {
        tableAreaRef.current
            .getElementsByClassName('rt-table')[0]
            .addEventListener('scroll', handleScroll);
    }

    return () => {
      if (tableAreaRef != null && tableAreaRef.current != null) {
        tableAreaRef.current
          .getElementsByClassName('rt-table')[0]
          .removeEventListener('scroll', handleScroll);
      }
    };
  }, [isLastPage]);

  useEffect(() => {
    callResizeEvent();
  }, [props.isUploading, isRecycleBin]);

  const handleUpload = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    enqueueTransferItems(
      currentFolderId,
      toggleResolveConflictsModal,
      setConflictingExistingFiles,
      setUploadingItems,
      setUploadingFolders,
      setConflictingItems,
      props.fetchFolderData,
      props.features.conflictResolution
    )(event.target.files);
  };

  const getItemLink = (original: any) => {
    let fileType = getFileType(original.name);
    if (isRecycleBin) {
      return;
    } else if (original.contentType == 'Folder') {
      return () => {
        props.invalidateSelection();
        props.invalidatePagination();
        if (hasItemReadPermissions(original.accessOrigin)) {
          if (props.isSearch) {
            const item = items.find(
              (i: Item) => i.instanceId == original.instanceId
            );
            setBreadcrumbsFromItemPath(item ? [item] : undefined);
          } else {
            props.addBreadcrumb(original.instanceId, original.name);
          }
          props.setCurrentFolderId(original.instanceId);
          props.fetchFolderData(original.instanceId, false, true, 0);
        } else {
          toast(toastType.error, 'Access to folder denied');
        }
      };
    } else if (
      original.contentType != null &&
      fileType.toLowerCase() == 'pdf' &&
      props.features.pdfMarkup &&
      !containsLockedByOther([original])
    ) {
      return () => {
        featureTracker.trackfeature('Portal_PdflinkClick');
        var url = props.urls.pdfMarkupUrl + '/' + projectId;
        url += '/Share/' + original.instanceId;
        window.open(url, '_blank');
      };
    } else if (
      (fileType == 'Word' ||
      fileType == 'PowerPoint' ||
      fileType == 'Excel') &&
      props.features.isWopiEnabled
    ) {
      return () => {
        featureTracker.trackfeature('Portal_WopiUsage');
        var url = props.urls.wopiUrl + 'Home/Detail/' + projectId + '/';
        url += original.instanceId + '?action=view';
        window.open(url, '_blank');
      };
    } else if (
      fileType == 'Text' ||
      fileType == 'Image' ||
      fileType == 'Document' ||
      fileType == 'Log' ||
      fileType == 'JSON' ||
      fileType == 'Video'
    ) {
      return () => {
        let win = window.open();
        openFileInNewWindow(
          original.accessUrl,
          fileType,
          win,
          original.name
        );
      };
    } else {
      return;
    }
  };
  const performDelete = (selectedItems: Item[], isRecycleBin: boolean) => {
    const xfdfFiles = selectedItems.filter(
      item => getFileExtension(item.name) === 'xfdf'
    );
    if (xfdfFiles.length > 0) {
      toggleConfirmationModalForDelete();
    } else {
      props.deleteItems(selectedItems, isRecycleBin);
      props.invalidateSelection();
    }
  };
  const getRecycleBinToolbarActions = () => {
    return [
      {
        title: t('Restore'),
        'data-testid': 'Restore',
        icon: <SvgRedo />,
        disabled: !allowRecycle(selectedItems),
        onClick: () => {
          featureTracker.trackfeature('Portal_Restore');
          props.restoreItems(selectedItems, isRecycleBin);
          props.invalidateSelection();
        },
      },
      {
        title: t('Permanently Delete'),
        'data-testid': 'PermanentDelete',
        icon: <SvgDelete />,
        disabled: !allowRecycle(selectedItems),
        onClick: () => {
          featureTracker.trackfeature('Portal_Delete');
          togglePermanentlyDeleteModal();
        },
      },
    ];
  };

  const performMove = (selectedItems: Item[], newParentId: string) => {
    const xfdfFiles = selectedItems.filter(
      item => getFileExtension(item.name) === 'xfdf'
    );
    if (xfdfFiles.length > 0) {
      toggleConfirmationModalForMove();
      setTargetFolderToMoveItem(newParentId);
    } else {
      props.move(selectedItems, newParentId, selectedItems[0].parentFolderId);
      props.invalidateSelection();
    }
  };

  const getToolbarActions = () => {
    let mainToolbarActions = [
      {
        title: t('Download'),
        icon: <SvgDownload />,
        disabled: !allowDownload(selectedItems),
        onClick: () => {
          featureTracker.trackfeature('Portal_Download');
          tryDownloadFiles(selectedItems);
        },
      },
      {
        title: t('Get a link'),
        'data-testid': 'GetLink',
        icon: <SvgLink />,
        disabled: !allowGetLink(selectedItems),
        onClick: () => {
          featureTracker.trackfeature('Portal_GetLink');
          copyToClipBoard(
            selectedItems[0],
            selectedItems[0].parentFolderId,
            projectId,
            props.urls.domainUrl,
            props.linkFormat
          );
        },
      },
      {
        'data-testid': 'MoveButton',
        title: t('Move'),
        icon: <SvgArrowRight />,
        disabled: !allowMove(selectedItems),
        onClick: () => {
          featureTracker.trackfeature('Portal_Move');
          toggleMoveModal();
        },
      },
      {
        title: t('Rename'),
        'data-testid': 'Rename',
        icon: <SvgRename />,
        disabled: !allowRename(selectedItems),
        onClick: () => {
          featureTracker.trackfeature('Portal_Rename');
          toggleIsRenameItemModal();
        },
      },
    ] as MenuItemAction[];

    if (
      !selectedItems ||
      !selectedItems.length ||
      selectedItems.length < 0 ||
      (containsFile(selectedItems) && containsFolder(selectedItems))
    ) {
      mainToolbarActions.push(
        {
          title: t('Lock'),
          icon: <SvgLock />,
          //@ts-ignore
          'data-testid': 'Lock',
          disabled: true,
        },
        {
          title: t('Unlock'),
          icon: <SvgLockUnlocked />,
          'data-testid': 'UnLock',
          disabled: true,
        }
      );
    }
    if (
      selectedItems &&
      selectedItems.length > 0 &&
      !containsFile(selectedItems)
    ) {
      mainToolbarActions.push(
        {
          title: t('Lock files'),
          icon: <SvgLock />,
          //@ts-ignore
          'data-testid': 'Lock',
          disabled: !allowFilesLockUnlockInFolder(selectedItems),
          onClick: () => {
            featureTracker.trackfeature('Portal_LockFilesInFolder');
            props.lockItem(selectedItems, true);
            props.invalidateSelection();
          },
        },
        {
          title: t('Unlock files'),
          icon: <SvgLockUnlocked />,
          'data-testid': 'UnLock',
          disabled: !allowFilesLockUnlockInFolder(selectedItems),
          onClick: () => {
            featureTracker.trackfeature('Portal_UnLockFilesInFolder');
            props.lockItem(selectedItems, false);
            props.invalidateSelection();
          },
        }
      );
    }
    if (
      selectedItems &&
      selectedItems.length > 0 &&
      !containsFolder(selectedItems)
    ) {
      mainToolbarActions.push(
        selectedItems[0].locked
          ? {
              title:
                selectedItems.length === 1
                  ? t('Unlock file')
                  : t('Unlock files'),
              icon: <SvgLockUnlocked />,
              //@ts-ignore
              'data-testid': 'UnLock',
              disabled: !allowUnlock(selectedItems),
              onClick: () => {
                featureTracker.trackfeature('Portal_UnLockFile');
                props.lockItem(selectedItems, false);
                props.invalidateSelection();
              },
            }
          : {
              title:
                selectedItems.length === 1 ? t('Lock file') : t('Lock files'),
              icon: <SvgLock />,
              //@ts-ignore
              'data-testid': 'Lock',
              disabled: !allowLock(selectedItems),
              onClick: () => {
                featureTracker.trackfeature('Portal_LockFile');
                props.lockItem(selectedItems, true);
                props.invalidateSelection();
              },
            }
      );
    }
    if (props.features.imodelHubPublishing && areAllItemsIdgn(selectedItems))
      mainToolbarActions.push({
        title: t('Post To Imodel Hub'),
        icon: <SvgImodelHollow />,
        disabled: !allowPostToImodelHub(selectedItems),
        badge: <SvgTechnicalPreviewMini />,
        onClick: () => {
          props.authenticateUser(
            selectedItems,
            currentFolderId,
            projectId,
            props.urls.domainUrl,
            props.linkFormat
          );
          props.invalidateSelection();
        },
      });

    if (
      props.features.fileVersioning &&
      selectedItems.length > 0 &&
      !containsFolder(selectedItems)
    ) {
      mainToolbarActions.push({
        title: t('Create a version'),
        //@ts-ignore
        'data-testid': 'CreateVersionButton',
        icon: <SvgVersions />,
        disabled: !allowCreateVersion(selectedItems),
        badge: <SvgTechnicalPreviewMini />,
        onClick: () => {
          featureTracker.trackfeature('Portal_CreateVersion');
          toggleCreateVersionModal();
        },
      });
    }
    if (selectedItems.length > 0 && !containsFile(selectedItems)) {
      if (context == 'Project')
        mainToolbarActions.push({
          title: areAllFoldersSynced(selectedItems)
            ? t('Unset From Synchronization')
            : t('Set For Synchronization'),
          icon: <SvgSync />,
          disabled: !allowSync(selectedItems),
          onClick: () => {
            props.invalidateSelection();
            if (areAllFoldersSynced(selectedItems)) {
              featureTracker.trackfeature('Portal_FolderSetSynchronization');
              props.unSynchronizeFolders(selectedItems);
            } else {
              featureTracker.trackfeature('Portal_FolderUnSetSynchronization');
              props.synchronizeFolders(selectedItems);
            }
          },
        });

      if (props.features.folderAccess && props.hasSetFolderPermissions)
        mainToolbarActions.push({
          icon: <SvgSettings />,
          onClick: () => {
            featureTracker.trackfeature('Portal_SetFolderAccess');
            toggleFolderAccessModal();
          },
          disabled: !allowSetFolderAccess(selectedItems),
          title: t('Set folder access'),
          //@ts-ignore
          'data-testid': 'SetFolderAccess',
        });
    }

    mainToolbarActions.push({
      title: t('Delete'),
      //@ts-ignore
      'data-testid': 'DeleteButton',
      icon: <SvgDelete />,
      disabled: !allowRecycle(selectedItems),
      onClick: () => {
        featureTracker.trackfeature('Portal_RecycleAction');
        performDelete(selectedItems, isRecycleBin);
      },
    });

    return mainToolbarActions;
  };

  const getRightToolbarActions = () => {
    let righToolbarActions: any[] = [];

    if (props.isUploading)
      righToolbarActions.push({
        title: t('Uploading Items'),
        icon: (
          <Spinner
            style={{
              width: 20,
              height: 20,
              marginTop: -2,
              marginLeft: -2,
              padding: 0,
            }}
            indeterminate={true}
          />
        ),
        onClick: () => {
          featureTracker.trackfeature('Portal_Upload');
          toggleProgressIndicator();
        },
      });

    righToolbarActions.push(
      {
        title: isRecycleBin ? t('Document View') : t('Recycle Bin'),
        icon: <SvgRecycle />,
        'data-testid': isRecycleBin ? 'DocumentView' : 'RecycleBin',
        onClick: () => {
          toggleRecycleBin();
          props.invalidateSelection();
          props.invalidatePagination();
          if (isRecycleBin) {
            props.jumpTo(props.currentFolderId);
            props.fetchFolderData(props.currentFolderId);
            props.fetchFolder(props.currentFolderId).then((item: Item) => {
              const isHome = props.currentFolderId === props.projectId;
              setBreadcrumbsFromItemPath(isHome ? undefined : [item]);
            });
          } else {
            props.initializeBreadcrumbs(
              [projectId, ''],
              [props.rootFolderName, t('Recycle Bin')]
            );
            props.fetchRecycleBinData();
          }
          scrollToTop();
        },
        isSelected: isRecycleBin,
        disabled: isLoading,
      },
      {
        id: 'InformationPanel',
        title: t('Information Panel'),
        'data-testid': 'InformationPanel',
        icon: <SvgInfo2 />,
        isSelected: isInformationPanelOpen,
        onClick: toggleInformationPanel,
      }
    );
    return righToolbarActions;
  };

  const isOnlySelected = (instanceId: string) =>
    selectedItemsIds.length === 1 &&
    selectedItemsIds.indexOf(instanceId) !== -1;

  const handleScroll = (event: any) => {
    var node = event.target;
    const bottom = node.scrollHeight - node.scrollTop - node.clientHeight < 1;
    if (bottom && !isLastPage) {
      props.fetchNextPage();
    }
  };

  const scrollToTop = () => {
    if (tableAreaRef != null && tableAreaRef.current != null) {
      tableAreaRef.current.getElementsByClassName('rt-tbody')[0].scrollTop = 0;
    }
  };

  const projectContent = (
    <>
      <TableBreadCrumbs
        id="TableBreadCrumbs"
        depth={props.breadcrumbs.depth}
        labels={props.breadcrumbs.labels}
        values={props.breadcrumbs.values}
        jumpTo={(value, i) => () => {
          props.setSearchText('');
          props.setIsSearch(false);
          if (value == '') {
            let path = projectId + '/';
            for (let i = 1; i < props.breadcrumbs.labels.length - 1; i++) {
              path = path + props.breadcrumbs.labels[i] + '/';
            }
            props.fetchDataByPath(path).then((items: Item[]) => {
              props.setCurrentFolderId(items[0].parentFolderId);
              props.fetchFolderData(items[0].parentFolderId);
              props.jumpToById(i, items[0].instanceId);
              props.invalidateSelection();
              props.invalidatePagination();
            });
          } else {
            if (isRecycleBin) {
              toggleRecycleBin();
              scrollToTop();
            }
            props.jumpTo(value);
            props.invalidateSelection();
            props.invalidatePagination();
            props.setCurrentFolderId(value);
            props.fetchFolderData(value);
          }

          if (props.urls.domainUrl + projectId !== window.location.href) {
            const redirect = props.linkFormat
              .replace('{projectId}', projectId)
              .replace('/{folderId}/', '')
              .replace('{itemId}', '');
            window.history.pushState(
              { ...window.history },
              window.document.title,
              redirect
            );
          }
        }}
      />
      <div className="toolbar-container">
        {!isRecycleBin && (
          <>
            <ButtonWithDropdown
              id="NewButton"
              title={isScreenMobile ? '' : t('New')}
              className={cx(
                { 'small-new-button': isScreenMobile },
                'new-button'
              )}
              icon={<SvgAdd className="button-icon" />}
              styleType="blue"
              style={{ margin: '0px 10px 0px 0px' }}
              data-testid="NewButton"
              onClick={() => {
                isScreenMobile && closeInformationPanel();
              }}
              dropdownActions={[
                {
                  title: t('Create Folder'),
                  icon: <SvgFolder />,
                  onClick: () => {
                    featureTracker.trackfeature('Portal_CreateFolder');
                    toggleCreateFolderModal();
                  },
                  //@ts-ignore
                  'data-testid': 'CreateFolder',
                  disabled: !hasItemWritePermissions(currentFolderAccessOrigin),
                },
                {
                  title: t('Upload'),
                  icon: <SvgUpload />,
                  // @ts-ignore
                  onClick: () => uploadRef.current.click(),
                  disabled: !hasItemWritePermissions(currentFolderAccessOrigin),
                },
              ]}
            />
            <input
              id="uploadInput"
              type="file"
              multiple
              style={{ display: 'none' }}
              ref={uploadRef}
              onChange={handleUpload}
              //reset the input's value to null to trigger the onchange event even if the same path is selected
              onClick={(event: any) => {
                event.target.value = null;
              }}
            />
          </>
        )}
        <div ref={leftToolbarRef} className="left-toolbar">
          {isRecycleBin ? (
            <Toolbar
              id="Toolbar"
              toolbarActions={getRecycleBinToolbarActions()}
              itemsShown={leftToolbarItemsShownCount}
            />
          ) : (
            <Toolbar
              id="Toolbar"
              toolbarActions={getToolbarActions()}
              menuButtonTitle={t('More')}
              onClick={() => {
                isScreenMobile && closeInformationPanel();
              }}
              itemsShown={leftToolbarItemsShownCount}
            />
          )}
        </div>
        {!isRecycleBin && (
          <div
            className="search-container"
            style={{ width: isSearchCollapsed ? 46 : 225 }}
          >
            <Search
              onCollapsedChange={(collapsed: boolean) => {
                if (collapsed) {
                  props.setIsSearch(false);
                }

                if (collapsed === isSearchCollapsed) {
                  return;
                }

                if (collapsed) {
                  featureTracker.trackfeature('Portal_Search');
                  // Resize after animation ends
                  window.setTimeout(() => {
                    setIsSearchCollapsed(true);
                    callResizeEvent();
                  }, 200);
                } else {
                  // Resize before animation starts
                  setIsSearchCollapsed(false);
                  callResizeEvent();
                }
              }}
            />
          </div>
        )}

        <div
          ref={rightToolbarRef}
          className="right-toolbar"
          style={{
            maxWidth: getRightToolbarActions().length * 50,
          }}
        >
          <Toolbar
            style={{ marginLeft: '10px' }}
            toolbarActions={getRightToolbarActions()}
            itemsShown={
              rightToolbarItemsShownCount! +
              // make both buttons show up instead of meatball with one button inside it
              (!props.isUploading && rightToolbarItemsShownCount == 1 ? 1 : 0)
            }
          />
          <UploadProgressIndicator
            isOpen={isProgressIndicatorOpen}
            parentRef={rightToolbarRef}
            onClick={toggleProgressIndicator}
          />
          <UploadQueueObserver />
        </div>
        {props.urls.ahaUrl !== '' && props.urls.ahaUrl != null && (
          <div className="ideas">
            <IdeasButton
              title={t('Submit an idea')}
              redirectUrl={props.urls.ahaUrl}
            />
          </div>
        )}
      </div>
      <div className="table-and-information" ref={tableAreaRef}>
        <DragAndDrop
          isEnable={!isRecycleBin}
          isDragging={isDragging && !isRecycleBin}
          setIsDragging={setIsDragging}
          handleDrop={enqueueTransferItems(
            props.currentFolderId,
            toggleResolveConflictsModal,
            setConflictingExistingFiles,
            setUploadingItems,
            setUploadingFolders,
            setConflictingItems,
            props.fetchFolderData,
            props.features.conflictResolution
          )}
        >
          <SelectTable
            data={items ? items : []}
            LoadingComponent={ShareLoader}
            columns={[
              {
                id: 'name',
                Header: <Header title={t('Name')} />,
                headerClassName: 'normal-font-weight-header name-header',
                accessor: row => row,
                minWidth: 200,
                maxWidth: 1100,
                Cell: ({ original }: any) =>
                  getCell(original, true, getItemLink(original)),
                sortMethod: itemNameSortFunction,
              },
              {
                Header: <Header title={t('Type')} />,
                headerClassName: 'normal-font-weight-header',
                accessor: 'contentType',
                minWidth: 90,
                maxWidth: 200,
                Cell: ({ original }: any) =>
                  original.contentType == 'Folder'
                    ? t<string>('Folder')
                    : t<string>(getFileType(original.name)),
                show: showTypeCol,
              },
              {
                Header: <Header title={t('File Size')} />,
                headerClassName: 'normal-font-weight-header',
                accessor: 'size',
                minWidth: 90,
                maxWidth: 250,
                Cell: ({ original }: any) =>
                  getFormattedFileSize(original.size),
                show: showFileSizeCol,
                sortMethod: itemSizeSortFunction,
              },
              {
                Header: <Header title={t('Modified')} />,
                headerClassName: 'normal-font-weight-header',
                accessor: 'modifiedTimeStamp',
                minWidth: 170,
                maxWidth: 300,
                Cell: ({ original }: any) =>
                  getFormattedDateAndTime(new Date(original.modifiedTimeStamp)),
                show: showModifiedDateCol,
              },
              {
                Cell: ({ original }: any) => (
                  <ContextMenu
                    instanceId={original.instanceId}
                    isOpen={
                      contextMenuOpen && isOnlySelected(original.instanceId)
                    }
                    toggleContextMenu={toggleContextMenu}
                    menuOptions={
                      isRecycleBin
                        ? getRecycleBinToolbarActions()
                        : getToolbarActions()
                    }
                    close={closeContext}
                  />
                ),
                minWidth: 64,
                maxWidth: 72,
              },
            ]}
            selected={selectedItemsIds}
            setSelected={props.setSelection}
            selectAll={
              items.length != 0 && items.length === selectedItemsIds.length
            }
            selectOnRowClick={true}
            keyField="instanceId"
            loading={
              isLoading ||
              props.breadcrumbs.labels.length == 0 ||
              (isDragging && !isRecycleBin)
            }
            loadingText={
              isDragging
                ? t<string>('Drop files to upload')
                : t<string>('Loading')
            }
            noDataText={isLoading ? '' : props.emptyTableLabel}
            NoDataComponent={() =>
              isRecycleBin ? (
                <EmptyRecycleBinGrid isLoading={isLoading} />
              ) : (
                <EmptyDocumentsGrid
                  isLoading={isLoading}
                  uploadRef={uploadRef}
                  currentFolderAccessOrigin={currentFolderAccessOrigin}
                />
              )
            }
            pageSize={items.length}
            minRows={items.length}
            sorted={gridSorted}
            toggleSelection={(
              key: string,
              shiftKeyPressed: boolean,
              rowInfo: any
            ) => {
              const itemsToBeSelected = handleCheckBoxSelection(
                selectedItems,
                rowInfo.instanceId,
                setLastSelectedWithoutShift
              );
              props.setSelection(itemsToBeSelected);
            }}
            getTrGroupProps={(state: any, rowInfo: any) => {
              return {
                onClick: (event: any) => {
                  const sortedItems = state.sortedData.map(
                    (x: any) => x._original
                  ) as Item[];

                  const itemsToBeSelected = getRowClickSelection(
                    sortedItems,
                    selectedItems,
                    rowInfo.original.instanceId,
                    event.shiftKey,
                    lastSelectedWithoutShift,
                    setLastSelectedWithoutShift
                  );
                  props.setSelection(itemsToBeSelected);
                },
              };
            }}
            onSortedChange={(newSorted: any[], column: Column) => {
              // Handle sort click
              if (column.id == 'name') {
                if (gridSorted[0] && gridSorted[0].id === newSorted[0].id) {
                  // Second click on Name resets sorting
                  setGridSorted([]);
                } else {
                  // First click sorts by descending
                  setGridSorted([{ desc: true, id: 'name' }]);
                }
                return;
              }

              if (
                gridSorted[0] &&
                gridSorted[0].desc &&
                gridSorted[0].id === newSorted[0].id
              ) {
                setGridSorted([]);
              } else {
                setGridSorted(newSorted);
              }
            }}
          />
        </DragAndDrop>
        <InformationPanel
          isOpen={isInformationPanelOpen}
          onClose={toggleInformationPanel}
          toggleManageVersionsModal={toggleManageVersionsModal}
          isRecycleBin={isRecycleBin}
        />
      </div>
      <CreateFolderModal
        isOpen={isCreateFolderModalOpen}
        onClose={toggleCreateFolderModal}
      />
      {isMoveModalOpen && (
        <FolderSelectDialog
          onClose={() => {
            toggleMoveModal();
          }}
          dialogTitle={t('Move')}
          getSubfolders={(current: any) =>
            props
              .fetchFolderData(current.instanceId, true, false, 0)
              .then(folders =>
                folders.filter(
                  folder => selectedItemsIds.indexOf(folder.instanceId) === -1
                )
              )
          }
          initialBreadcrumbs={[
            {
              name: props.rootFolderName,
              instanceId: projectId,
            } as IBreadcrumb,
          ]}
          createNewFolder={(parent: IBreadcrumb, name: string) =>
            createFolderRequest(name, parent.instanceId).then(
              response =>
                mapToItem([response.changedInstance.instanceAfterChange])[0]
            )
          }
          onSave={(target: any) => {
            performMove(selectedItems, target.instanceId);
          }}
          saveLabel={t('Move')}
          selectedItemsIds={selectedItemsIds}
        />
      )}
      <RenameItemModal
        isOpen={isRenameItemModalOpen}
        onClose={toggleIsRenameItemModal}
      />
      <SetFolderAccessModal
        isOpen={isFolderAccessModalOpen}
        onClose={toggleFolderAccessModal}
        emptyTableLabel={t('There Are No Roles Created For This Project')}
      />
      <CreateVersionModal
        isOpen={isCreateVersionModalOpen}
        onClose={toggleCreateVersionModal}
      />
      <ManageVersionsModal
        isOpen={isManageVersionModalOpen}
        onClose={toggleManageVersionsModal}
      />
      <PermanentlyDeleteModal
        isOpen={isPermanentlyDeleteModalOpen}
        onClose={togglePermanentlyDeleteModal}
      />
      <ConfirmationForXfdfFile
        title={t('Move item')}
        primaryButtonLabel={t('Move')}
        warning={t('Move Xfdf File Confirmation')}
        isOpen={isConfirmationModalForMoveOpen}
        primaryButtonHandle={() => {
          props.move(
            selectedItems,
            targetFolderToMoveItem,
            selectedItems[0].parentFolderId
          );
          invalidateSelection();
          toggleConfirmationModalForMove();
        }}
        onClose={toggleConfirmationModalForMove}
      />
      <ConfirmationForXfdfFile
        title={t('Delete item')}
        primaryButtonLabel={t('Delete')}
        warning={t('Delete Xfdf File Confirmation')}
        isOpen={isConfirmationModalForDeleteOpen}
        primaryButtonHandle={() => {
          props.deleteItems(selectedItems, isRecycleBin);
          invalidateSelection();
          toggleConfirmationModalForDelete();
        }}
        onClose={toggleConfirmationModalForDelete}
      />
      {props.features.conflictResolution && (
        <ResolveConflictsModal
          currentFolderId={props.currentFolderId}
          uploadingFiles={uploadingItems}
          uploadingFolders={uploadingFolders}
          conflictingFiles={conflictingItems}
          conflictingExistingFiles={conflictingExistingFiles}
          isOpen={isResolveConflicsModalOpen}
          onClose={toggleResolveConflictsModal}
        />
      )}
    </>
  );

  return (
    <main className="fit-to-parent">
      {!error ? (
        projectContent
      ) : (
        <GenericErrorPage
          title={t(error.errorTitle)}
          caption={t(error.errorDetails)}
        />
      )}
    </main>
  );
};

export const mapStateToProps = (store: AppState): StateProps => {
  return {
    projectId: store.navigation.projectId,
    currentFolderId: store.navigation.currentFolderId,
    currentFolderAccessOrigin: store.navigation.currentFolderAccessOrigin,
    context: store.navigation.context,
    linkFormat: store.navigation.linkFormat,
    items: store.data.items,
    isLoading: store.data.isLoading,
    isUploading: store.upload.isLoading,
    isInitialLoad: store.data.isInitialLoad,
    isSearch: store.data.isSearch,
    emptyTableLabel: store.data.emptyTableLabel,
    searchText: store.searchText,
    error: store.data.error,
    urls: store.urls,
    breadcrumbs: store.breadcrumbs,
    features: store.features,
    selectedItems: store.selection.selectedItems,
    selectedItemsIds: store.selection.selectedItemsIds,
    isLastPage: store.data.isLastPage,
    rootFolderName: store.breadcrumbs.rootFolderName,
    hasSetFolderPermissions: store.projectPermissions.rbacAllowInstanceSecurity,
  };
};

export const mapDispatchToProps = (dispatch: any): ActionProps => {
  // @ts-ignore
  return bindActionCreators(
    {
      setProjectId,
      setCurrentFolderId,
      setCurrentFolderAccessOrigin,
      fetchData,
      fetchFolderData,
      fetchFolder,
      fetchSearchResults,
      fetchRecycleBinData,
      fetchDataByPath,
      fetchNextPage,
      setSelection,
      invalidateSelection,
      deleteItems,
      restoreItems,
      synchronizeFolders,
      unSynchronizeFolders,
      jumpTo,
      jumpToById,
      addBreadcrumb,
      initializeBreadcrumbs,
      lockItem,
      authenticateUser,
      setSearchText,
      setIsSearch,
      invalidatePagination,
      setPage,
      setPages,
      move,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectShare);
