import { User } from 'oidc-client';
import {
  LogDispatcherClient,
  LogType,
} from '@bentley/log-dispatcher-client-js';
export const logger = (() => {
  let user: User;
  let contextId: string;
  let logger: LogDispatcherClient;

  const initialiseLogger = (
    logDispatcherUrl: string,
    userContext: User,
    projectId: string
  ) => {
    user = userContext;
    contextId = projectId;
    logger = new LogDispatcherClient({
      application: 'Project Share Portal',
      gprId: 2567,
      url: logDispatcherUrl,
      tokenProvider: () => Promise.resolve(user!.access_token),
      intervalMs: 4000,
      retryCount: 2,
    });
  };

  const log = (level: LogType, message: string, params?: any[]) => {
    if (logger != null) {
      logger.log(message, level, `Sharel portal: ${message}`, {
        ContextId: contextId,
        UserId: user!.profile!.sub,
        ...params,
      });
    }
  };
  const logError = (message: string, params?: any[]) => {
    log('Error', message, params);
  };
  const logInfo = (message: string, params?: any[]) => {
    log('Info', message, params);
  };
  const logWarn = (message: string, params?: any[]) => {
    log('Warning', message, params);
  };

  return { initialiseLogger, log, logError, logInfo, logWarn };
})();
