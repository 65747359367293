import { Item } from '../entities/entities';
import { store } from '../store/configureStore';
import { defaultOptions, defaultShareOptions } from '../services/fetchHelper';
import { SET_SHARE_PERMISSIONS, SET_USER_ROLES } from './actionConstants';
import { getProjectShareUrl, getRbacUrl } from '../services/utillities';
import { invalidateData } from './dataActions';
import { READ, READ_WRITE, READ_WRITE_DELETE } from '../constants';
import { Role, RolePermission, UserRolePermissions } from '../entities/types';
import { toast, toastType } from '../services/toastService';
import { logger } from '../services/logger';

export const setSharePermissions = (rolePermissions: any) => {
  return {
    type: SET_SHARE_PERMISSIONS,
    payload: rolePermissions,
  };
};

export const setUserRoles = (userRoles: any[]) => {
  return {
    type: SET_USER_ROLES,
    payload: userRoles,
  };
};

export const setFolderAccess = (
  folder: Item,
  userRolePermissions: UserRolePermissions[]
) => {
  return (dispatch: any, getState: any) => {
    const userRoles = getState().roles.userRoles;
    const lastRole =
      userRolePermissions.length > 1 ? userRolePermissions.pop() : undefined;

    const url = `${getProjectShareUrl()}/Folder/${folder.instanceId}`;
    toast(toastType.information, 'Set Folder Access Start');
    return Promise.all(
      userRoles.map((userRole: any) => {
        let userRolePermission = userRolePermissions.find(
          userRolePermission =>
            userRolePermission.instanceId === userRole.instanceId
        );
        if (userRolePermission) {
          const body = JSON.stringify(
            userRolePermissionsData(folder, userRolePermission)
          );
          logger.logInfo(`Started setting folder access: ${url}, ${body}`, [
            url,
            body,
          ]);

          return fetch(url, {
            ...defaultShareOptions(),
            method: 'POST',
            body,
          }).then(response => {
            if (response.ok) {
              logger.logInfo('Set folder access successfully');
            } else {
              logger.logError(
                `Failed to set folder access: ${response.statusText}`,
                [response.statusText]
              );
            }
            return response.status;
          });
        } else {
          return -1;
        }
      })
    ).then(statusCodes => {
      if (
        statusCodes.every(statusCode => statusCode === 200 || statusCode === -1)
      ) {
        if (lastRole) {
          const body = JSON.stringify(
            userRolePermissionsData(folder, lastRole)
          );
          fetch(url, {
            ...defaultShareOptions(),
            method: 'POST',
            body,
          }).then(response => {
            if (response.status === 200) {
              dispatch(invalidateData());
              if (document.getElementsByClassName('rt-tbody')[0]) {
                document.getElementsByClassName('rt-tbody')[0].scrollTop = 0;
              }
              toast(toastType.success, 'Set Folder Access Success');
            } else {
              toast(toastType.error, 'Set Folder Access Failure');
            }
          });
        } else {
          dispatch(invalidateData());
          if (document.getElementsByClassName('rt-tbody')[0]) {
            document.getElementsByClassName('rt-tbody')[0].scrollTop = 0;
          }
          toast(toastType.success, 'Set Folder Access Success');
        }
      } else {
        toast(toastType.error, 'Set Folder Access Failure');
      }
    });
  };
};

const userRolePermissionsData = (
  item: Item,
  rolePermissions: UserRolePermissions
) => {
  return {
    instance: {
      instanceId: item.instanceId,
      schemaName: 'ProjectShare',
      className: 'Folder',
      changeState: 'modified',
    },
    requestOptions: {
      CustomOptions: {
        IsSecured: rolePermissions.secure,
        ContextRoleId: rolePermissions.instanceId,
        ObjectRoleId: rolePermissions.permissionTypeId,
      },
    },
  };
};

const buildUserRolePermissionData = (data: any): any => {
  const userRoles = store.getState().roles.userRoles;
  const rolePermissions = store.getState().roles.rolePermissions;
  const readId = rolePermissions.find(
    (role: RolePermission) => role.name === READ
  ).instanceId;
  const writeId = rolePermissions.find(
    (role: RolePermission) => role.name === READ_WRITE
  ).instanceId;
  const deleteId = rolePermissions.find(
    (role: RolePermission) => role.name === READ_WRITE_DELETE
  ).instanceId;

  let rolePermissionMapping: any = [];
  data.relationshipInstances.forEach((instance: any) => {
    rolePermissionMapping[instance.relatedInstance.instanceId] =
      instance.properties.ObjectRoleId;
  });

  return userRoles.map((userRole: Role) => {
    const roleId = rolePermissionMapping[userRole.instanceId];
    return {
      name: userRole.name,
      instanceId: userRole.instanceId,
      isGuest: roleId === readId,
      isGuestChecked: roleId === readId,
      isContributor: roleId === writeId,
      isContributorChecked: roleId === writeId,
      isAdministrator: roleId === deleteId,
      isAdministratorChecked: roleId === deleteId,
    };
  });
};

export const fetchUserRolePermissionsData = (item: Item) => {
  return async () => {
    try {
      const url = `${getRbacUrl()}/Object/${
        item.instanceId
      }?$select=ObjectRole.*,ObjectRole-forward-Role.*`;
      logger.logInfo(`Started fetching user role permission data: ${url}`, [
        url,
      ]);
      const response = await fetch(url, { ...defaultOptions() });
      const json = await response.json();
      logger.logInfo('Fetched user role data');
      return buildUserRolePermissionData(json.instances[0]);
    } catch (error) {
      logger.logError(`Failed fetch user role permission data: ${error}`, [
        error,
      ]);
    }
  };
};

export const fetchUserRoles = () => {
  return async (dispatch: any, getState: any) => {
    try {
      const url = `${getRbacUrl()}/${getState().navigation.context}/${
        getState().navigation.projectId
      }/Role?atleastoneuser=true&$filter=Permission.ServiceGPRId+eq+2567`;
      logger.logInfo(`Started fetching user roles: ${url}`, [url]);
      const response = await fetch(url, { ...defaultOptions() });
      const json = await response.json();
      const userRoles = json.instances.map((instance: any) => {
        return {
          name: instance.properties.Name,
          instanceId: instance.instanceId,
        };
      });
      dispatch(setUserRoles(userRoles));
      logger.logInfo('Fetched user roles successfully');
    } catch (error) {
      logger.logError(`Failed fetch user roles: ${error}`, [error]);
    }
  };
};

export const fetchPermissionRoles = () => {
  return async (dispatch: any, getState: any) => {
    try {
      const url = `${getRbacUrl()}/ObjectType/${
        getState().instancePermissions.folderType
      }/Role`;
      logger.logInfo(`Started fetching permission roles: ${url}`, [url]);
      const response = await fetch(url, { ...defaultOptions() });
      const json = await response.json();
      if (json && json.instances) {
        let roles = json!.instances!.map((instance: any) => {
          return {
            name: instance.properties.Name,
            instanceId: instance.instanceId,
          };
        });
        logger.logInfo('Fetched permission roles successfully');
        dispatch(setSharePermissions(roles));
      } else {
        logger.logError(`Failed fetch permission roles`);
      }
    } catch (error) {
      logger.logError(`Failed fetch permission roles: ${error}`, [error]);
    }
  };
};
